import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LessonDetailComponent } from './lesson-detail/lesson-detail.component';
import { LessonsComponent } from './lessons/lessons.component';

const routes: Routes = [
	{ path: 'lessons/:lessonId', component: LessonsComponent },
	{ path: 'lessonDetail/:lessonId', component: LessonDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LessonsRoutingModule { }
