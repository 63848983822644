
<main>
	
	<!-- toast -->
	<div #toastElement 
		id="toastElement" 
		class="toast" 
		role="alert" 
		aria-live="assertive" aria-atomic="true"
	>					
		<div class="toast-header">
			<strong class="toast_text mr-auto">Notification</strong>
		</div>

		<div class="toast-body toast_text"></div>
	</div>


<form (submit)="onSubmit()">
	<div class="formGroup">
		<input
		type="email"
		class="formControl"
		placeholder="Email Address"
		#userEmail
		autocomplete="username"
		required
		/>
	</div>
	<div class="formGroup">
		<input
		type="password"
		class="formControl"
		placeholder="Password"
		#userPwd
		autocomplete="current-password"
		required
		/>
	</div>
	<div class="formGroup">
		<input
		type="button"
		class="btn btnPrimary"
		value="Sign Up"
		(click)="signUp(userEmail.value, userPwd.value)"
		/>
	</div>
	<div class="formGroup">
		<span class="or"><span class="orInner">Or</span></span>
	</div>
	<!-- Continue with Google -->
	<div class="formGroup">
		<button
		type="button"
		class="btn googleBtn"
		(click)="authService.GoogleAuth()"
		>
		<i class="fab fa-google-plus-g"></i>
		Continue with Google
		</button>
	</div>

	<div>
		<span>
			Already have an account?
			<span class="redirect" routerLink="/sign-in">Log In</span>
		</span>
	</div>
</form>
</main>