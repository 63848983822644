<main>
	<!-- list of courses -->
	<div class="list">
		<div class="listItem" *ngFor="let course of courses" (click)="navigateTo(course)">
			<p>ID: {{ course.courseId }} | {{ course.courseCode }}</p>
			<p>{{ course.courseName }}</p>
			<p>{{ course.courseDescription }} - {{ course.courseLevel }}</p>
			<p>Empezar ahora: <a (click)="navigateTo(course)">{{ course.courseUrl }}</a></p>
			<!--<img src="course.courseImage" alt="course.courseImage" />-->
		</div>
	</div>
</main>