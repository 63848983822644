import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lesson } from './lesson';

@Component({
  selector: 'app-lessons',
  templateUrl: './lessons.component.html',
  styleUrls: ['./lessons.component.css'],
})
export class LessonsComponent implements OnInit {

	//list of spanish to english lessons
	lessons: Array<Lesson> = [
		{
			lessonId: "1",
			lessonCode: "ING101-1",
			lessonName: "Lesson 1",
			lessonDescription: "How do you say...",
			lessonUrl: "lesson1",
			lessonImage: "imagename.png"
		},
		{
			lessonId: "2",
			lessonCode: "ING101-2",
			lessonName: "Lesson 2",
			lessonDescription: "Where is...",
			lessonUrl: "lesson2",
			lessonImage: "imagename.png"
		},
	];

	lessonId!: string;

	constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

	ngOnInit(): void {
		if(this.activatedRoute.snapshot.paramMap.get("lessonId") != null) {
			this.lessonId = this.activatedRoute.snapshot.paramMap.get('lessonId')!;
		}
	}

	navigateTo(lesson: Lesson) {
		//store the lesson in local storage
		localStorage.setItem("lessonId", JSON.stringify(lesson));

		//navigate to the lesson's lessonUrl
		this.router.navigate(["lessonDetail", this.lessonId]);
	}

}
