import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpeechService } from 'src/app/services/speech.service';

@Component({
	selector: 'app-lesson-detail',
	templateUrl: './lesson-detail.component.html',
	styleUrls: ['./lesson-detail.component.css'],
	providers: [SpeechService]
})
export class LessonDetailComponent implements OnInit {

	lessonId!: string;

	constructor(
		private activatedRoute: ActivatedRoute, 
		private router: Router,
		public speechService: SpeechService
	) {
		this.speechService.init();
	}

	ngOnInit(): void {
		if(this.activatedRoute.snapshot.paramMap.get("lessonId") != null) {
			this.lessonId = this.activatedRoute.snapshot.paramMap.get('lessonId')!;
		}
	}

	startService() {
		this.speechService.start();
	}

	stopService() {
		this.speechService.stop()
	}

	navigateTo(url:string) {
		this.router.navigate([url]);
	}
}
