<main>
	
	<!-- toast -->
	<div #toastElement 
		id="toastElement" 
		class="toast" 
		role="alert" 
		aria-live="assertive" aria-atomic="true"
	>					
		<div class="toast-header">
			<strong class="toast_text mr-auto">Notification</strong>
		</div>

		<div class="toast-body toast_text"></div>
	</div>

	<!-- list of items -->
	<div class="list">
		<div class="listItem" *ngFor="let menuItem of menuItems" (click)="navigateTo(menuItem)">
			<p>{{ menuItem.title }}</p>
			<p>{{ menuItem.description }}</p>
		</div>
	</div>
</main>
