export const environment = {
	production: false,


	// firebase: {
	// 	apiKey: "AIzaSyCbzKG_RnS4V5QDg2FoxUEOF39bZpzH77w",
	// 	authDomain: "my-personal-kitchen.firebaseapp.com",
	// 	locationId: 'us-central',
	// 	databaseURL: "https://my-personal-kitchen.firebaseio.com",
	// 	projectId: "my-personal-kitchen",
	// 	storageBucket: "my-personal-kitchen.appspot.com",
	// 	messagingSenderId: "45285634202",
	// 	appId: "1:45285634202:web:e82d159118561426422f23"
	// },


	firebase: {
		apiKey: "AIzaSyDzeGnESxQ5p_VVg-I7F0uEi1cDDLugjNI",
		authDomain: "americandream-49f79.firebaseapp.com",
		locationId: 'us-central',
		projectId: "americandream-49f79",
		storageBucket: "americandream-49f79.appspot.com",
		messagingSenderId: "600460440085",
		appId: "1:600460440085:web:17c46d6b313f111e19541c",
		measurementId: "G-TXZQGH74QG"
	},
	



	database: {
		baseUrl: "https://www.mypersonalkitchen.com/globals/database/",
		select: "select.php",
		select2: "select2.php",
		selectIn: "selectIn.php",
		selectLike: "selectLike.php",
		selectLikeTwoColumns: "selectLikeTwoColumns.php",
		insert: "insert.php",
		update: "update.php",
		delete: "delete.php",
		delete2: "delete2.php",
		deleteIn: "deleteIn.php",
		fileMover: "fileMover.php",
		photos: "photos.php",
		fileImageMover: "fileImageMover"
	}
};