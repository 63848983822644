import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { CoursesComponent } from './features/courses/courses.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { LessonsComponent } from './features/lessons/lessons/lessons.component';
import { HomeComponent } from './structure/home/home.component';
/*
		courses
		lessons
		reports
		*/
const routes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: 'home', component: HomeComponent },
	{ path: 'signup', component: SignupComponent },
	{ path: 'signin', component: SigninComponent },
	{ path: 'dashboard', component: DashboardComponent },
	{ path: 'courses', component: CoursesComponent },
	{ path: 'lessons', component: LessonsComponent },
	/* { path: 'reports', component: ReportsComponent }, */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
