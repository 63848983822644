import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Course } from './course';

@Component({
	selector: 'app-courses',
	templateUrl: './courses.component.html',
	styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

	//list of spanish to english courses
	courses: Array<Course> = [
		{
			courseId: "1",
			courseCode: "ING101",
			coursePrerequisites: [],
			educationalCredentialAwarded: "Certificado",
			numberOfCredits: 3,
			courseName: "Ingles 101",
			courseDescription: "Aprende Ingles 101",
			courseLevel: "Principiante",
			courseUrl: "ingles101",
			courseImage: "imagename.png"
		},
		{
			courseId: "2",
			courseCode: "ING102",
			coursePrerequisites: ["ING101"],
			educationalCredentialAwarded: "Certificado",
			numberOfCredits: 3,
			courseName: "Ingles 102",
			courseDescription: "Aprende Ingles 102",
			courseLevel: "Intermedio",
			courseUrl: "ingles102",
			courseImage: "imagename.png"
		},
		{
			courseId: "3",
			courseCode: "ING103",
			coursePrerequisites: ["ING101", "ING102"],
			educationalCredentialAwarded: "Certificado",
			numberOfCredits: 3,
			courseName: "Ingles 103",
			courseDescription: "Aprende Ingles 103",
			courseLevel: "Avanzado			",
			courseUrl: "ingles103",
			courseImage: "imagename.png"
		},
	];

	constructor(private router: Router) { }

	ngOnInit(): void {}

	navigateTo(course: Course) {
		//store the course in local storage
		localStorage.setItem("course", JSON.stringify(course));

		//navigate to the course's courseUrl
		this.router.navigate(["lessons", course.courseId]);
	}
}
