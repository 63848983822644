<app-header
	[title]="title"
	[menuItems]="menuItems"
	(onTitleChange)="onTitleChange($event)"
	(onMenuItemChange)="onMenuNavigate($event)">
</app-header>

<div id="spacer_header"></div>

<router-outlet 
	(activate)='onActivate($event)'
></router-outlet>

<div id="spacer_footer"></div>

<app-footer></app-footer>