
<main>
	<div class="card">
		<div class="card-header">
			Our Mission
		</div>
		<div class="card-body">
			<h5 class="card-title">
				What is American Dream?
			</h5>
			<p class="card-text">
				American Dream is a platform where you get solutions for your problems and where you can learn English based on the natural way of learning language.  In other words, you will learn English the same way you learned to speak spanish as a child.
			</p>
		</div>
	</div>


	<div class="card">
		<div class="card-header">
			Our Method
		</div>
		<div class="card-body">
			<h5 class="card-title">From The Beginning</h5>
			<p class="card-text">
				When a child is born, the child does not know any language.  They are just observers.  By listening and through repitition the child begins to learn to understand the parents' language.<br /><br />
				With American Dream, we teach in the same way.
			</p>
		</div>
	</div>


	<div class="card">
		<div class="card-header">
			Sign Up
		</div>
		<div class="card-body">
			<h5 class="card-title">Account</h5>
			<p class="card-text">
				Create a new account or sign in if you already have one.
			</p>
			<a (click)="signup()" class="btn btn-success">Sign Up</a>
			<a (click)="signin()" class="btn btn-success">Sign In</a>
		</div>
	</div>


	<div class="card">
		<div class="card-header">
			Guest
		</div>
		<div class="card-body">
			<h5 class="card-title">Continue As Guest</h5>
			<p class="card-text">Try a lesson without creating an account.</p>
			<a (click)="proceedAsGuest()" class="btn btn-primary">Proceed</a>
		</div>
	</div>
</main>