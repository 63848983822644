import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

	//list of menuItems with properties title:string, description:string
	menuItems = [
		{
			title: "Courses",
			description: "List of courses",
			url: "courses"
		},
		{
			title: "Lessons",
			description: "Start learning English.  Go here to view lessons.",
			url: "lessons"
		},
		{
			title: "Reports",
			description: "Review your progress.  Go here to view reports.",
			url: "reports"
		},
	];

	constructor(private router: Router) { }

	ngOnInit(): void {
	}

	navigateTo(menuItem: any) {
		//navigate to the menuItem's title
		this.router.navigate([menuItem.url]);
	}

}
