import { Injectable } from '@angular/core';

declare var webkitSpeechRecognition: any;

@Injectable({
	providedIn: 'root'
})
export class SpeechService {

	recognition = new webkitSpeechRecognition();
	isStoppedSpeechRecog = false;
	public text = '';
	tempWords!: string;

	constructor() { }

	init() {
		this.recognition.interimResults = true;
		this.recognition.lang = 'en-US';
		this.recognition.addEventListener('result', 
			(e: { results: Iterable<unknown> | ArrayLike<unknown>; }) => {
			let eresults:any = Array.from(e.results);
			if (eresults[0].isFinal) {
				console.log(
					"isFinal" + eresults[0].isFinal +  "\n" +
					"value: " + eresults[0][0].transcript + "\n" +  
					"confidence: " + eresults[0][0].confidence + "\n" + 
					"score: " + (eresults[0][0].confidence * 100) + "%" 
				);
			}

			//debugger;
			const transcript = Array.from(e.results)
				.map((result:any) => result[0])
				.map((result) => result.transcript)
				.join('');
				this.tempWords = transcript;
				console.log(transcript);
		});
	}

	
	start() {
		this.isStoppedSpeechRecog = false;
		this.recognition.start();
		console.log("Speech recognition started")
		this.recognition.addEventListener('end', (condition: any) => {
		  if (this.isStoppedSpeechRecog) {
		    this.recognition.stop();
		    console.log("End speech recognition")
		  } else {
		    this.wordConcat()
		    this.recognition.start();
		  }
		});
	   }
	   stop() {
		this.isStoppedSpeechRecog = true;
		this.wordConcat()
		this.recognition.stop();
		console.log("End speech recognition")
	   }
	 
	   wordConcat() {
		this.text = this.text + ' ' + this.tempWords + '.';
		this.tempWords = '';
	   }
}