import { DatabaseService } from './../../services/database/database.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

	@ViewChild('toastElement') toastElement!:ElementRef;

	constructor(private database: DatabaseService, private router: Router) { }

	ngOnInit(): void {
	}

	onSubmit() {
		console.log("submit");
	}


	signIn(email:string, password:string) {
		
		//Resolve platter items for the current platter
		let obj = {
			table: 'americandream_users',
			firstFieldName: 'email',
			firstFieldValue: email,
			secondFieldName: 'password',
			secondFieldValue: password
		};
		this.database.select2(obj)
			.subscribe((data:any) => {

				let message = "Data selected from database",
					status = "success";
				
				//Navigate to dashboard or show error message
				if(message == data.message && status == data.status) {
					this.router.navigate(['/dashboard']);
				} else {
					GlobalService.showToast(
						"Invalid email or password", 
						"btn-danger",
						this.toastElement.nativeElement.id
					);
					return;
				}
			});


	}

}
